/*------------------------------------------------------------------
Import styles
------------------------------------------------------------------*/

import '../styles/main.scss';

/*------------------------------------------------------------------
Import modules
------------------------------------------------------------------*/

import $ from 'jquery';
import plyr from 'plyr';
import inView from 'in-view';
import { tns } from "tiny-slider";
import Navigation from './application/Navigation';
import ElementStatistic from './application/ElementStatistic';
import JobApplication from './application/JobApplication';
import BlogProfileFilter from './application/BlogProfileFilter';
import ElementGallery from './application/ElementGallery';
import GATracking from './application/GATracking';
import WhitePage from './application/WhitePage';
import FooterContactForm from './application/FooterContactForm';
import FooterNewsletterForm from './application/FooterNewsletterForm';
import ImageTextModule from './application/ImageTextModule';
import CarouselCaseStudies from './application/CarouselCaseStudies';
'use strict';
$(function () {
  // primary nav menu buttons
  new Navigation();

  // Statistic element animation
  new ElementStatistic();

  // Hide / show job apply section
  new JobApplication();

  // Filter blog profile posts by tag
  new BlogProfileFilter();

  // Element gallery
  new ElementGallery();

  // Google tracking events
  new GATracking();

  // Whitepage Event
  new WhitePage();

  // Footer Contact Form
  new FooterContactForm();

  // Footer Newsletter Form
  new FooterNewsletterForm();

  // Image Text Module
  new ImageTextModule();

  // media element video
  plyr.setup(document.querySelectorAll('.element-media-panel__video'), {
    loadSprite: false
  });
  new CarouselCaseStudies();

  /*------------------------------------------------------------------
  Toast to your success
  ------------------------------------------------------------------*/

  inView('.js-in-view').on('enter', function (el) {
    return el.classList.add('inView');
  });
  var $body = $('body');
  $body.on('click', '.js-accordion-toggle', function (e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    var $accordion = $this.closest('.js-accordion');
    if ($accordion.hasClass('active')) {
      $accordion.removeClass('active');
      $accordion.find('.js-accordion-content').slideUp();
    } else {
      $accordion.addClass('active');
      $accordion.find('.js-accordion-content').slideDown();
    }
  });
  $body.on('click', '.js-tabbed-content-trigger', function (e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    var id = $this.attr('data-id');
    var $parent = $this.closest('.js-tabbed-content');
    if (!$this.hasClass('active')) {
      $parent.find('.js-tabbed-content-trigger').removeClass('active');
      $parent.find('.js-tabbed-content-target').removeClass('active');
      $parent.find(".js-tabbed-content-trigger[data-id=\"".concat(id, "\"]")).addClass('active');
      $parent.find(".js-tabbed-content-target[data-id=\"".concat(id, "\"]")).addClass('active');
    }
  });
  $body.on('click', '.js-tabbed-content-alt-trigger', function (e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    var id = $this.attr('data-id');
    var $parent = $this.closest('.js-tabbed-content-alt');
    if (!$this.hasClass('active')) {
      $parent.find('.js-tabbed-content-alt-trigger').removeClass('active');
      $parent.find('.js-tabbed-content-alt-target').removeClass('active');
      $parent.find(".js-tabbed-content-alt-trigger[data-id=\"".concat(id, "\"]")).addClass('active');
      $parent.find(".js-tabbed-content-alt-target[data-id=\"".concat(id, "\"]")).addClass('active');
    }
  });
  var slider = tns({
    container: ".js-slider-testimonials",
    items: 1,
    mouseDrag: true,
    nav: true,
    controls: true,
    slideBy: 1,
    mode: 'gallery',
    loop: true
  });
  if (document.querySelector('.background-video')) import('./application/BackgroundVideo');
});